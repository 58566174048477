import { TZ } from "@chopshop/tz";
import {
  ChoiceList,
  ContextualSaveBar,
  FooterHelp,
  Icon,
  LegacyCard,
  Link,
  Page,
  TextField,
} from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { UNLIMITED_NUM_OF_FAILED_CYCLES_SENTINEL } from "@smartrr/shared/entities/Billing";
import { ISOTimeString } from "@smartrr/shared/entities/ISOTimeString";
import { IOrganizationBillingTime } from "@smartrr/shared/entities/Organization";
import { billingDaysChoices } from "@smartrr/shared/utils/dateUtils";
import { isEqual, isNumber } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  updateOrgBillingRetryConfig,
  updateOrgBillingTime,
  updateOrgConfirmationDaysConfig,
  updateSelectedBillingDays,
} from "@vendor-app/app/_state/actionCreators/organization";
import {
  useActiveOrganizationSelector,
  useActiveOrganizationSubSelector,
} from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { colors } from "@vendor-app/constants/colors";

import { BillingTime, isInDst, toDstTime } from "./components/BillingTime";
import { NumericDropDown } from "./components/NumericDropDown";
import { SetupStoreAccess } from "../../AdminSetupRoute/libs/store";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBillingSettingsProps {}

const TransactionPageBox = styled(Box)`
  & .Polaris-Page-Header,
  & .Polaris-Page {
    padding: 0px;
    & h1 {
      margin-top: 2rem;
    }
  }
  & .Polaris-LegacyCard {
    margin-top: 2rem;
    & .Polaris-LegacyCard__Section h2 {
      font-size: 14px;
      font-weight: 400;
    }
    & .Polaris-LegacyCard__Section .card-subheading {
      margin-bottom: 10px;
      margin-top: 5px;
    }
    & .retry-freq {
      & .Polaris-Select__SelectedOption {
        color: var(--p-color-text);
      }
    }
  }
  & .Polaris-FooterHelp {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    & .Polaris-FooterHelp__Text {
      display: flex;
      font-size: 14px;
      & span {
        margin-right: 8px;
        & .Polaris-Icon__Svg {
          fill: #00a0ac;
        }
      }
    }
    & a {
      color: #2c6ecb;
    }
  }
`;
const FieldWrapper = styled.div`
  margin-top: 2rem;
`;
const Subheading = styled.div`
  color: ${colors.helpText};
  margin-top: 20px;
`;

const delayBetweenRetriesOptions = [
  { label: "1 minute (use only for testing)", value: 60 },
  { label: "1 day", value: 86400 },
  { label: "2 days", value: 172800 },
  { label: "3 days", value: 259200 },
  { label: "5 days", value: 432000 },
  { label: "7 days", value: 604800 },
  { label: "14 days", value: 1209600 },
  { label: "30 days", value: 2592000 },
];

const cyclesBeforeAutoCancel = [
  { label: "1 cycle", value: 0 },
  { label: "2 cycles", value: 1 },
  { label: "3 cycles", value: 2 },
  { label: "6 cycles", value: 5 },
  { label: "12 cycles", value: 11 },
  { label: "Never automatically cancel", value: UNLIMITED_NUM_OF_FAILED_CYCLES_SENTINEL },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function BillingEngineSettings(props: IBillingSettingsProps): JSX.Element {
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const activeOrganizationId = useSmartrrVendorSelector(state => state.vendorOrganizations.activeOrganizationId);
  const billingTimeUpdating = useSmartrrVendorSelector(state => state.vendorOrganizations.billingTimeUpdating);
  const orgBillingRetriesConfigUpdating = useSmartrrVendorSelector(
    state => state.vendorOrganizations.billingRetriesConfigUpdating
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const activeOrg = useActiveOrganizationSelector();

  const setupBillingDays = SetupStoreAccess.useSetup()?.billingDays;
  const dayVals = useMemo(() => billingDaysChoices.map(day => day.value), [billingDaysChoices]);

  const [selectedBillingDays, setSelectedBillingDays] = useState<string[]>(setupBillingDays ?? dayVals);
  const [orgNumBillingRetries, setOrgNumBillingRetries] = useState(
    useActiveOrganizationSubSelector(org => org?.numBillingRetries)
  );
  const [billingConfirmationWaitingDays, setBillingConfirmationWaitingDays] = useState(
    useActiveOrganizationSubSelector(org => org?.billingConfirmationWaitingDays)
  );
  const [orgDelayBetweenRetries, setOrgDelayBetweenRetries] = useState(
    useActiveOrganizationSubSelector(org => org?.delayBetweenRetries)
  );
  const [orgNumFailedCyclesBeforeCancel, setOrgNumFailedCyclesBeforeCancel] = useState(
    useActiveOrganizationSubSelector(org => org?.numFailedCyclesBeforeCancel)
  );
  const [orgBillingTime, setOrgBillingTime] = useState<IOrganizationBillingTime>(
    useActiveOrganizationSubSelector(org => org?.billingTime)
  );
  const [orgBillingTimeZone, setOrgBillingTimeZone] = useState<TZ>(
    useActiveOrganizationSubSelector(org => org?.billingTimezone)
  );

  const initialValues = useMemo(
    () => ({
      orgBillingTime,
      orgBillingTimeZone,
      orgNumBillingRetries,
      orgNumFailedCyclesBeforeCancel,
      orgDelayBetweenRetries,
      selectedBillingDays,
      billingConfirmationWaitingDays,
    }),
    [activeOrg]
  );

  const hasChanges = !isEqual(initialValues, {
    orgBillingTime,
    orgBillingTimeZone,
    orgNumBillingRetries,
    orgNumFailedCyclesBeforeCancel,
    orgDelayBetweenRetries,
    selectedBillingDays,
    billingConfirmationWaitingDays,
  });

  const onSave = useCallback(async () => {
    setIsSaving(true);
    if (
      initialValues.orgBillingTime !== orgBillingTime ||
      initialValues.orgBillingTimeZone !== orgBillingTimeZone
    ) {
      await updateBillingTime();
    }

    if (initialValues.orgNumBillingRetries !== orgNumBillingRetries) {
      await onChangeBillingRetryConfig();
    }

    if (initialValues.billingConfirmationWaitingDays !== billingConfirmationWaitingDays) {
      await onChangeOrgConfirmationDays();
    }

    if (initialValues.orgDelayBetweenRetries !== orgDelayBetweenRetries) {
      await onChangeBillingDelayBetweenRetriesConfig();
    }

    if (initialValues.orgNumFailedCyclesBeforeCancel !== orgNumFailedCyclesBeforeCancel) {
      await onChangeBillingNumFailedCyclesBeforeCancelConfig();
    }

    if (!isEqual(initialValues.selectedBillingDays, selectedBillingDays)) {
      await onChangeSelectedBillingDays();
    }

    setIsSaving(false);
  }, [
    initialValues,
    orgBillingTime,
    orgBillingTimeZone,
    orgNumBillingRetries,
    orgDelayBetweenRetries,
    orgNumFailedCyclesBeforeCancel,
    selectedBillingDays,
    billingConfirmationWaitingDays,
  ]);

  const onDiscard = useCallback(() => {
    if (initialValues.orgBillingTime !== orgBillingTime) {
      setOrgBillingTime(initialValues.orgBillingTime);
    }
    if (initialValues.orgBillingTimeZone !== orgBillingTimeZone) {
      setOrgBillingTimeZone(initialValues.orgBillingTimeZone);
    }
    if (initialValues.orgNumBillingRetries !== orgNumBillingRetries) {
      setOrgNumBillingRetries(initialValues.orgNumBillingRetries);
    }
    if (initialValues.orgDelayBetweenRetries !== orgDelayBetweenRetries) {
      setOrgDelayBetweenRetries(initialValues.orgDelayBetweenRetries);
    }
    if (initialValues.orgNumFailedCyclesBeforeCancel !== orgNumFailedCyclesBeforeCancel) {
      setOrgNumFailedCyclesBeforeCancel(initialValues.orgNumFailedCyclesBeforeCancel);
    }
    if (initialValues.selectedBillingDays !== selectedBillingDays) {
      setSelectedBillingDays(initialValues.selectedBillingDays);
    }
    if (initialValues.billingConfirmationWaitingDays !== billingConfirmationWaitingDays) {
      setBillingConfirmationWaitingDays(initialValues.billingConfirmationWaitingDays);
    }
  }, [
    initialValues,
    orgBillingTime,
    orgBillingTimeZone,
    orgNumBillingRetries,
    orgDelayBetweenRetries,
    orgNumFailedCyclesBeforeCancel,
    selectedBillingDays,
    billingConfirmationWaitingDays,
  ]);

  const onBillingDayUpdate = useCallback((selected: string[], _: string) => {
    // sorting days so that array is not out of order when passed to ep
    const sorted = selected.sort((a, b) => (dayVals.indexOf(a) > dayVals.indexOf(b) ? 1 : -1));

    setSelectedBillingDays(sorted);
  }, []);

  const updateBillingTime = useCallback(async () => {
    if (!activeOrganizationId) {
      throw "No active organization.";
    }

    const billingTime = orgBillingTime;
    const billingTimezone = orgBillingTimeZone as TZ;

    await dispatch(
      updateOrgBillingTime({
        orgBillingTime: {
          billingTime,
          billingTimezone,
        },
      })
    );

    const isDst = isInDst(billingTimezone);
    const [hh, mm] = ISOTimeString.fromString(
      isDst ? (toDstTime(billingTime as unknown as string) as unknown as ISOTimeString) : billingTime
    );
    const timeToDisplay = DateTime.now()
      .setZone(billingTimezone)
      .set({
        hour: hh,
        minute: mm,
        millisecond: 0,
      })
      .toLocaleString(DateTime.TIME_SIMPLE);
    addToast(`Billing time updated to ${timeToDisplay}, ${billingTimezone}`);
  }, [activeOrganizationId, orgBillingTime, orgBillingTimeZone]);

  const onChangeBillingRetryConfig = useCallback(async () => {
    if (!activeOrganizationId) {
      throw "No active organization.";
    }

    if (!orgNumBillingRetries) {
      throw "Invalid number of retries";
    }

    if (+orgNumBillingRetries > 10) {
      throw "The max allowed number of retries is 10";
    }

    await dispatch(
      updateOrgBillingRetryConfig({
        numBillingRetries: +orgNumBillingRetries,
      })
    );

    addToast(`Number of billing retry attempts updated to ${orgNumBillingRetries}`);
  }, [activeOrganizationId, orgNumBillingRetries]);

  const onChangeOrgConfirmationDays = useCallback(async () => {
    if (!activeOrganizationId) {
      addToast("No active organization", true);
      return;
    }

    if (!billingConfirmationWaitingDays || +billingConfirmationWaitingDays < 1) {
      addToast("Invalid number of days", true);
      return;
    }

    await dispatch(
      updateOrgConfirmationDaysConfig({
        billingConfirmationWaitingDays: +billingConfirmationWaitingDays,
      })
    );

    addToast(`Number of waiting for confirmation days updated to: ${billingConfirmationWaitingDays}`);
  }, [activeOrganizationId, billingConfirmationWaitingDays]);

  const onChangeBillingDelayBetweenRetriesConfig = useCallback(async () => {
    if (!activeOrganizationId) {
      throw "No active organization.";
    }

    if (!orgDelayBetweenRetries) {
      throw "Invalid delay between retries";
    }

    await dispatch(
      updateOrgBillingRetryConfig({
        delayBetweenRetries: orgDelayBetweenRetries,
      })
    );

    addToast(`Delay between billing retry attempts updated`);
  }, [activeOrganizationId, orgDelayBetweenRetries]);

  const onChangeBillingNumFailedCyclesBeforeCancelConfig = useCallback(async () => {
    if (!activeOrganizationId) {
      throw "No active organization.";
    }

    if (!isNumber(orgNumFailedCyclesBeforeCancel)) {
      throw "Invalid number of failed cycles";
    }

    await dispatch(
      updateOrgBillingRetryConfig({
        numFailedCyclesBeforeCancel: orgNumFailedCyclesBeforeCancel,
      })
    );

    addToast(`Number of failed cycles before cancel updated`);
  }, [activeOrganizationId, orgNumFailedCyclesBeforeCancel]);

  const onChangeSelectedBillingDays = useCallback(async () => {
    if (!selectedBillingDays.length) {
      addToast("Select at least one billing day");
      return;
    }

    await dispatch(updateSelectedBillingDays(selectedBillingDays)).then(res => {
      if (res.type === "UPDATED_SELECTED_BILLING_DAYS") {
        addToast("Billing days updated");
        initialValues.selectedBillingDays = res.payload.setup.billingDays!;
      }
    });
  }, [selectedBillingDays]);

  useEffect(() => setupBillingDays && setSelectedBillingDays(setupBillingDays), [setupBillingDays]);

  return (
    <TransactionPageBox>
      {hasChanges ? (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            content: "Discard",
            onAction: onDiscard,
          }}
          saveAction={{
            loading: isSaving,
            content: "Save",
            onAction: () => onSave(),
          }}
        />
      ) : null}
      <Page title="Transaction Settings">
        <LegacyCard title="Billing Schedule">
          <LegacyCard.Section title="Billing Time">
            <Subheading className="card-subheading">
              The time of day you&apos;ll charge your customers for their subscriptions and generate orders. Note:
              this will be when your billing period begins, but some customers will be billed slightly after this
              time. Updates to existing billing times will not take effect until each customer&apos;s next billing
              cycle.
              {!!orgBillingTime && !!orgBillingTimeZone && (
                <BillingTime
                  billingTime={orgBillingTime}
                  billingTimezone={orgBillingTimeZone}
                  onChange={(billingTime: ISOTimeString, billingTimezone: TZ) => {
                    setOrgBillingTime(billingTime);
                    setOrgBillingTimeZone(billingTimezone);
                  }}
                  isAsyncUpdating={billingTimeUpdating}
                />
              )}
            </Subheading>
          </LegacyCard.Section>
          <LegacyCard.Section title="Billing Days">
            <Subheading className="card-subheading">
              The days when you&apos;ll charge your customers for their subscriptions and generate orders. Note:
              recurring transactions that occur on inactive billing days will be rescheduled to the next active
              billing date.
            </Subheading>
            <ChoiceList
              allowMultiple
              title=""
              choices={billingDaysChoices}
              selected={selectedBillingDays}
              onChange={onBillingDayUpdate}
            />
          </LegacyCard.Section>
          <LegacyCard.Section title="Failed Payments">
            {typeof orgNumBillingRetries === "number" && (
              <div className="retry-freq" data-testid="payment-attempts-retry-wrapper">
                <TextField
                  label="Retrying payment attempts"
                  type="number"
                  helpText="Number of times a customer's payment should be retried when payment fails"
                  value={orgNumBillingRetries + ""}
                  onChange={e => setOrgNumBillingRetries(+e)}
                  autoComplete="off"
                  min={1}
                  max={5}
                />
              </div>
            )}

            {typeof orgDelayBetweenRetries === "number" && (
              <FieldWrapper data-testid="time-before-retry-wrapper">
                <NumericDropDown
                  label="Time before retrying payment"
                  options={delayBetweenRetriesOptions}
                  value={orgDelayBetweenRetries}
                  onChange={value => setOrgDelayBetweenRetries(value)}
                  isAsyncUpdating={orgBillingRetriesConfigUpdating}
                  helpText="Amount of time after a failed payment attempt before retrying"
                />
              </FieldWrapper>
            )}
            {typeof orgNumFailedCyclesBeforeCancel === "number" && (
              <FieldWrapper data-testid="max-failed-payments-wrapper">
                <NumericDropDown
                  label="Max failed payment cycles"
                  options={cyclesBeforeAutoCancel}
                  value={orgNumFailedCyclesBeforeCancel}
                  onChange={value => setOrgNumFailedCyclesBeforeCancel(value)}
                  isAsyncUpdating={orgBillingRetriesConfigUpdating}
                  helpText="Number of failed payment cycles before the subscription is auto-canceled"
                />
              </FieldWrapper>
            )}
          </LegacyCard.Section>
          {typeof billingConfirmationWaitingDays === "number" ? (
            <LegacyCard.Section title="3DS payment authentication">
              <Subheading className="card-subheading">
                For subscriptions that require 3D Secure (3DS)—an additional security layer for online credit and
                debit card transactions—this setting determines the length of time Smartrr should wait for
                customer confirmation before considering a transaction failed.
              </Subheading>

              <div className="confirmation-period">
                <TextField
                  label="Confirmation grace period (days)"
                  type="number"
                  helpText="The number of days Smartrr will wait for a customer to confirm a recurring subscription payment that requires 3DS"
                  value={String(billingConfirmationWaitingDays)}
                  onChange={value => setBillingConfirmationWaitingDays(+value)}
                  autoComplete="off"
                  min={1}
                />
              </div>
            </LegacyCard.Section>
          ) : (
            <React.Fragment />
          )}
        </LegacyCard>
        <FooterHelp>
          <Icon source={InfoMinor} color="base" />
          Learn more about<div>&nbsp;</div>
          <Link
            external={true}
            url="https://help.smartrr.com/docs/support/transactions/how-do-i-set-up-rules-around-failed-payments"
          >
            Transaction Settings.
          </Link>
        </FooterHelp>
      </Page>
    </TransactionPageBox>
  );
}
