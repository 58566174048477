import { BigIntString } from "./BigIntString";
import { ISODateString } from "./ISODateString";
import { ISmartrrOrder } from "./Order";
import { IPurchaseStateWithCustomerRelationship } from "./PurchaseState";
import { ISharedEntityFieldsWithNumeridId } from "./shared/SharedEntityFields";
import { PaginationResult } from "../utils/paginatedQuery";

export type BillStatus = "pending" | "processing" | "succeeded" | "failed" | "canceled";
export enum BillStatusEnum {
  Pending = "pending",
  Processing = "processing",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}
export const DEFAULT_NUM_BILLING_RETRIES = 3;
export const UNLIMITED_NUM_OF_FAILED_CYCLES_SENTINEL = 101;

export interface IBillCSV {
  "Transaction ID": string | number | undefined;
  "Initial bill attempt": ISODateString;
  "Last update time": ISODateString;
  Customer: string | undefined;
  Total: string | undefined;
  Status: string;
  "Retry count": string | number | undefined;
  "Failure reason": string | undefined;
}

export interface IBill extends ISharedEntityFieldsWithNumeridId {
  uniqueId: string;
  billPaymentExternalId?: string;
  billAmount: BigIntString;
  billCurrency: string;
  status: BillStatus;
  metadata: object;
  billingDate?: ISODateString;
  retryCount: number;
  order?: ISmartrrOrder;
  error?: string | null;
}

export type IBillWithPurchStAndCustomerRelationship = IBill & {
  st: IPurchaseStateWithCustomerRelationship;
};

export type IBillWithPurchStAndCustomerRelationshipPaginatedResponse =
  PaginationResult<IBillWithPurchStAndCustomerRelationship>;

export interface IBillAudit extends Omit<IBill, "updatedDate"> {
  billId: number;
  createdDate: ISODateString;
}

export interface FailedBill {
  id: number;
  retryCount: number;
  error: string | null | undefined;
}
