import { Page, Spinner } from "@shopify/polaris";
import React, { useEffect } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { BillingEngineSettings } from "./BillingEngineSettings";
import { TabsContainer } from "../components/elements/styles";
import { SetupStoreAccess } from "../AdminSetupRoute/libs/store";

export function AdminBillingSettingsRoute(): JSX.Element {
  const isLoading = useSmartrrVendorSelector(state => state.vendorOrganizations.isLoading);
  const isSetupLoading = SetupStoreAccess.useLoading();
  const { initialize: initializeSetup } = SetupStoreAccess.useActions();
  const setup = SetupStoreAccess.useSetup();

  useEffect(() => {
    initializeSetup(true);
  }, [initializeSetup]);

  if (isLoading || isSetupLoading || !setup) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Page narrowWidth>
        <TabsContainer>
          <BillingEngineSettings />
        </TabsContainer>
      </Page>
    </React.Fragment>
  );
}
